<template>
  <div>
<!--    <div class="container py-3 mt-2 text-left">-->
    <div class="row justify-content-between align-items-center px-3">
      <div class="col-auto">
        <h3 class="h3">
          Vessel Overview
        </h3>
      </div>
      <div class="col-auto">
        <button class="btn btn-xs btn-dark font-weight-bold e-text-white"
                v-if="user.vessel_id == null"
                @click="goBack"><font-awesome-icon
          icon="chevron-left"/> Go Back
        </button>
      </div>
    </div>
<!--    </div>-->
    <!--    <div class="container">-->
    <div class="mx-2">
      <div class="row no-gutters">
        <div class="col-sm-12">
          <nav class="bg-light">
            <div class="nav nav-tabs nav-fill w-100" id="nav-tab" role="tablist">
              <li class="nav-item">
                <router-link
                  :to="{name: 'VesselParticular' }"
                  tag="a"
                  class="nav-link border border-bottom-0"
                  :class="{'active text-white': isActiveRoute('VesselParticular')}"
                  role="tab"
                  data-toggle="list"
                >
                  Particulars
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{name: 'VesselSisterShip' }"
                  tag="a"
                  class="nav-link border border-bottom-0"
                  :class="{'active text-white': isActiveRoute('VesselSisterShip')}"
                  role="tab"
                  data-toggle="list"
                >
                  Sister Ship
                </router-link>
              </li>
              <li class="nav-item">

                <router-link v-if="this.$route.params.id == 424" :to="{name: 'VesselDocuments' }"
                   tag="a"
                   class="nav-link border border-bottom-0"
                   :class="{'active text-white': isActiveRoute('VesselDocuments')}"
                   role="tab"
                   data-toggle="list"
                >
                  Documents
                </router-link>
                <a v-else href="#" class="nav-link border border-bottom-0 disabled">Documents</a>
              </li>
              <li class="nav-item">
                <router-link
                  v-if="isAllowed('accident-incident.general.view.crew.info') && isOwnVessel()"
                  :to="{name: 'VesselCrewLists' }"
                  tag="a"
                  class="nav-link border border-bottom-0"
                  :class="{'active text-white': isActiveRoute('VesselCrewLists')}"
                  role="tab"
                  data-toggle="list"
                >
                  Crew List
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  :to="{name: 'VesselHistory' }"
                  tag="a"
                  class="nav-link border border-bottom-0"
                  :class="{'active text-white': isActiveRoute('VesselHistory')}"
                  role="tab"
                  data-toggle="list"
                >
                  Vessel History
                </router-link>
                <!--                <router-link :to="{name: 'FlagHistoryIndex' }"-->
                <!--                             tag="a"-->
                <!--                             class="nav-link border border-bottom-0"-->
                <!--                             :class="{'active': isActiveRoute('FlagHistoryIndex')}"-->
                <!--                             role="tab"-->
                <!--                             data-toggle="list"-->
                <!--                > Vessel History-->
                <!--                </router-link>-->
              </li>

            </div>
          </nav>

          <div class="py-2 px-1 text-left border border-top-0">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>

    <!--    </div>-->
  </div>
</template>

<script>
import {vueAppMixin} from '@/mixins/vueAppMixin'
import {permissionMixin} from "@/mixins/permissionMixins";
import {mapGetters} from "vuex";

export default {
  name: 'VesselOverview',
  mixins: [vueAppMixin,permissionMixin],
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style scoped>
.active {
  background-color: #dc3545 !important;
}
</style>
